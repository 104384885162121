<template>
  <div :class="show ? 'h5' : 'wtop'">
    <!-- <div style="color:#ff0000;background:#843900;height:32px;font-weight:bold;width:100%;position:fixed;top:0;line-height:32px;" v-show="!show">网站在升级中...</div> -->
    <div class="maintop">
      <!-- <div class="top_top">
         <div>北京星润科技有限公司欢迎您！</div>
         <div>联系客服：4008066111</div>
         </div> -->
      <div class="top_con">
        <div class="con_left">
          <img src="../assets/ximg/logob.png" alt="星润科技" title="星润科技" v-if="show" @click="topage('/')"/>
           <img src="../assets/ximg/logo.png" alt="星润科技" title="星润科技" v-else @click="topage('/')"/>
           <div class="boxede">
            <!-- <div class="txtr">高新技术企业</div> -->
            <div class="txtr">专注软件开发</div>
           </div>
        </div>
        <div class="menu" v-show="show">
          <div class="phone">
            <a href="tel:4008066111">400-8066-111</a>
          </div>
          <div class="right_btn" @click="topage('/customizing')">沟通需求</div>
          <img src="../assets/ximg/menu.png" alt="menu" @click="toMenu" />
        </div>
        <div class="con_right" v-show="!show">
          <div class="txtLine">
            <div
              class="btxt"
              :class="active == '/' ? 'active' : ''"
              @click="topage('/')"
            >
              <a href="#" title="">首页</a>
            </div>
            <div
              class="btxt"
              :class="
                active == '/collection' ||
                active == '/chatAi' ||
                 active == '/wky' ||
                 active == '/shoppingMall' ||  
                 active == '/chainReform' ||     
                 active == '/computing' ||              
                active == '/digital'                
                  ? 'active'
                  : ''
              "
            >
              <a href="#" title="">产品方案</a>
              <div class="list fadeInDow">
                <div class="item" @click="topage('/collection')">NFT数藏</div>
                <div class="item" @click="topage('/wky')">微客赚</div>   
                <div class="item" @click="topage('/computing')">边缘云计算平台</div>                            
                <div class="item" @click="topage('/digital')">数字钱包</div> 
                <div class="item" @click="topage('/shoppingMall')">电商系统</div>   
                <div class="item" @click="topage('/chainReform')">企业链改</div>
                <div class="item" @click="topage('/chatAi')">星科AI</div>
                <div class="item" @click="noOpen('/save')">元宇宙</div>  
              </div>
            </div>
            <div
              class="btxt"
              :class="active == '/save' ? 'active' : ''
              "
              @click="topage('/save')"
            >
              <a href="#" title="">定制开发</a>
            </div>
            <div
              class="btxt"
              :class="
                active == '/gallery' || active == '/gallerypc' ? 'active' : ''
              "
              @click="topage('/gallery')"
            >
              <a href="#" title="">案例展示</a>
            </div>
            <div
              class="btxt"
              :class="
                active == '/newsCenter' || active == '/teamdetal' ? 'active' : ''
              "
              @click="topage('/newsCenter')"
            >
              <a href="#" title="">新闻中心</a>
            </div>

            <div
              class="btxt"
              :class="
                active == '/about' ||
                active == '/recruiting' ||
                active == '/recuiDetail'
                  ? 'active'
                  : ''
              "
              @click="topage('/about')"
            >
              <a href="#" title="">关于我们</a>
            </div>
          </div>
          <div class="phone">400-8066-111</div>
          <div
            class="right_btn"
            :class="active == '/customizing' ? 'actbtn' : ''"
            @click="topage('/customizing')"
          >
            沟通需求
          </div>
        </div>
      </div>
    </div>
    <!-- 手机端点开右上菜单图片显示 -->
    <div>

      <van-overlay
        :show="mshow"
        @click="mshow = false"
      >
        <el-collapse-transition>
          <div class="txtLine" v-show="mshow" @click.stop>
            <div
              class="btxt"
              :class="active == '/' ? 'active' : ''"
              @click="topage('/')"
            >
              首页
            </div>
       
            <div class="btxt" @click="topage('/wky')">微客赚</div>
            <div class="menu">
            <div class="laber" @click="smshow=!smshow">
              产品方案
              <i :class="smshow?'el-icon-arrow-up':'el-icon-arrow-down'"></i>              
            </div>
                <div class="mneut fadeInDow" v-show="smshow">
                <div class="btxt" :class="active == '/collection' ? 'active' : ''"   @click="topage('/collection')">NFT数藏</div>
                <div class="btxt" :class="active == '/wky' ? 'active' : ''"  @click="topage('/wky')">微客赚</div>
                <div class="btxt" :class="active == '/computing' ? 'active' : ''"  @click="topage('/computing')">边缘云计算平台</div>
                <div class="btxt" :class="active == '/digital' ? 'active' : ''"  @click="topage('/digital')">数字钱包</div>
                <div class="btxt" :class="active == '/shoppingMall' ? 'active' : ''"  @click="topage('/shoppingMall')">电商系统</div>
                <div class="btxt" :class="active == '/chainReform' ? 'active' : ''"  @click="topage('/chainReform')">企业链改</div>
                <div class="btxt" :class="active == '/chatAi' ? 'active' : ''"  @click="topage('/chatAi')">星科AI</div>
                <div class="btxt" :class="active == '/save' ? 'active' : ''"  @click="noOpen('/save')">元宇宙</div>
              </div>
          </div>
            <div
              class="btxt"
              :class="active == '/save' ? 'active' : ''"
              @click="topage('/save')"
            >
              定制开发
            </div>
            <div
              class="btxt"
              :class="active == '/gallery' ? 'active' : ''"
              @click="topage('/gallery')"
            >
              案例展示
            </div>
            <div
              class="btxt"
              :class="active == '/newsCenter' ? 'active' : ''"
              @click="topage('/newsCenter')"
            >
              新闻中心
            </div>

            <div
              class="btxt"
              :class="active == '/about' ? 'active' : ''"
              @click="topage('/about')"
            >
              关于我们
            </div>
          </div>
        </el-collapse-transition>
      </van-overlay>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false, //判断是否是手机
      mshow: false,
      active: "/index",
      actives: "index",
      style: {
        backgroundColor: "",
      },
      color: false,
      smshow:true
    };
  },
  created() {
    this.active = this.$route.path;
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    if (this._isMobile()) {
      this.show = true;
    } else {
      this.show = this.$store.getters.phone;
    }
  },
  watch: {
    "$store.getters.phone": function (val) {
      this.show = val;
    },
    deep: true,
    immediate: true,
  },
  methods: {
    _isMobile() {
      //检测是否为手机
      let flag = navigator.userAgent.match(
        /(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    toMenu() {
      this.mshow = !this.mshow;
    },
    noOpen() {
      this.$toast("敬请期待");
    },
    handleScroll() {
      //滚动头部背景变色
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // let offsetTop = document.querySelector(".header").offsetTop;
      //设置背景颜色的透明读
      if (scrollTop) {
        this.style.backgroundColor = `rgba(61, 61, 61,${
          scrollTop / (scrollTop + 80)
        })`;
        this.color = true;
      } else if (scrollTop == 0) {
        this.style.backgroundColor = `rgba(61, 61, 61,${
          scrollTop / (scrollTop + 80)
        })`;
        this.color = false;
      }
    },
    to(url) {
      location.href = url;
    },

    topage(e) {
      this.active = e;
      this.$router.replace({ path: e });
      const { aplus_queue } = window;
      aplus_queue.push({
        action: "aplus.sendPV",
        arguments: [
          {
            is_auto: false,
          },
          {
            param1: "111",
            param2: "222",
          },
        ],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$t-mf: "Arial";
.wtop {
  z-index: 9999;
  width: 100%;
  position: fixed;
  width: 100%;
  z-index: 999;
  height: 70px;
  max-width: 100%;
  min-width: 1200px;
  margin: 0 auto;
  background: #ffffff;

  .maintop {
    max-width: 1200px;
    min-width: 750px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    .top_top {
      width: 100%;
      margin: 0 auto;
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #dedede;
      margin-top: 32px;
    }
    .top_con {
      // max-width:1200px;
      min-width: 750px;
      width: 100%;
      margin: 0 auto;
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      .con_left {
        display: flex;
        align-items: center;
        img {
          width: 120px;
          height: auto;
        }
        .boxede{
          margin:0 15px ;
          .txtr{
            white-space: nowrap;
            color: #000000;
            font-size: 10px;
          }
        }
      }
      .menu {
        display: flex;
      }
      .con_right {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .txtLine {
          display: flex;
          color: #fff;
          align-items: center;
          .btxt {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #eee;
            text-align: center;
            min-width: 84px;
            height: 45px;
            line-height: 45px;
            margin: 0 20px;
            cursor: default;
            a {
              color: #000000;
            }

            .list {
              display: none;
              box-shadow: 0.13333rem 0.06667rem 0.13333rem #000000;
              padding: 10px;
              background: black;
              .item:hover {
                font-weight: bold;
                white-space: nowrap;
              }
            }
          }

          .btxt:hover {
            opacity: 1;
            // font-size: 18px;
            color: #fff;
            border-bottom: 3px solid #2b6bff;
            a {
              color: #2b6bff !important;
            }
            .list {
              display: block;
              .item {
                cursor: pointer;
                white-space: nowrap;
              }
            }
          }
        }
        .active {
          border-bottom: 2px solid #2b6bff;
          a {
            color: #2b6bff !important;
            font-weight: 600;
          }
        }
        .phone {
          padding: 0 30px 0 30px;
          height: 48px;
          background: url("../assets/image/phone.png") left center no-repeat;
          background-size: 28px 28px;
          font-size: 14px;
          font-weight: 400;
          color: #000000;
          line-height: 48px;
          margin-left: 100px;
          white-space: nowrap;
        }
        .right_btn {
          width: 106px;
          height: 37px;
          border: 1px solid #2b6bff;
          border-radius: 18px;
          text-align: center;
          line-height: 37px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #2b6bff;
          // margin-left: 300px;
          cursor: pointer;
        }
        .actbtn,
        .right_btn:hover {
          cursor: pointer;
          border: 1px solid #2b6bff;
          background: #2b6bff;
          color: #ffffff;
        }
      }
    }
  }
}
.h5 {
  display: flex;
  justify-content: center;
  opacity: 0.85;
  background: rgba(61, 61, 61, 0.7);
  //  box-shadow: 0px 0px 48px 0px rgba(48, 81, 232, 0.2);
  z-index: 9999;
  width: 100%;
  position: fixed;
  .maintop {
    min-width: 100%;
    max-width: 750px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    .top_top {
      width: calc(100% - 64px);
      margin: 15px auto;
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #dedede;
    }
    .top_con {
      max-width: 750px;
      width: 100%;
      padding: 22px;
      box-sizing: border-box;
      // margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .con_left {
        display: flex;
        align-items: center;
        img {
          width: 200px;
          height: auto;
        }        
        .boxede{
          margin:0 15px ;
          .txtr{
            white-space: nowrap;
            color: #ffffff;
            font-size: 20px;

          }
        }
      }
      .menu {
        display: flex;
        align-items: center;
        .phone {
          // padding: 0 40px 0 50px;
          // height: 48px;
          // background: url("../assets/image/phone.png") left center
          // 	no-repeat;
          // background-size: 48px 48px;
          // a {
          // 	font-size: 18px;
          // 	font-weight: 400;
          // 	color: #ffffff;
          // 	line-height: 48px;
          // }
          display: none;
        }
        .right_btn {
          display: none;
        }
        img {
          height: 48px;
        }
      }
      .con_right {
        display: flex;
        justify-content: space-between;
        .txtLine {
          display: flex;
          color: #fff;
          .btxt {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #eee;
            text-align: center;
            min-width: 74px;
            height: 45px;
            line-height: 45px;
            margin: 0 20px;
            cursor: default;
          }

          .btxt:hover {
            opacity: 1;
            // font-size: 18px;
            color: #fff;
            border-bottom: 3px solid #abb3c1;
          }
        }
        .active {
          border-bottom: 3px solid #2b6bff;
        }
        .phone {
          padding: 0 40px 0 50px;
          height: 48px;
          background: url("../assets/image/phone.png") left center no-repeat;
          background-size: 48px 48px;
          font-size: 18px;
          font-weight: 400;
          color: #ffffff;
          line-height: 48px;
          white-space: nowrap;
        }
        .right_btn {
          width: 106px;
          height: 37px;
          border: 1px solid #ffffff;
          border-radius: 18px;
          text-align: center;
          line-height: 37px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          // margin-left: 300px;
          cursor: pointer;
        }
        .right_btn:hover {
          cursor: pointer;
          border: 1px solid #2b6bff;
          background: #2b6bff;
        }
      }
    }
  }
  .txtLine {
    // display: flex;
    flex: 1;
    width: 60%;
    flex-direction: column;
    background: #ffffff;
    height: 100%;
    position: fixed;
    right: 0px;
    z-index: 9999;
    overflow: auto;
    box-sizing: border-box;
    padding-top: 100px;
    .menu{
      display: flex;
      flex-direction: column;     
      .laber{
        font-size: 32px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #2a2d32;
      margin: 0 38px;
      flex: 1;
      text-align: left;
      min-height: 100px;
      line-height: 100px;
      border-bottom: 1px solid #eee;
      display: flex;
    align-items: center;
    justify-content: space-between;
      }
    }
    .mneut{
      // width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background: #fffcfc;
      padding: 0 33px 0 80px;
     

    }
    .btxt {
      cursor: pointer;
      font-size: 32px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #2a2d32;
      margin: 0 38px;
      flex: 1;
      text-align: left;
      min-height: 100px;
      line-height: 100px;
      border-bottom: 1px solid #eee;
    }
    .active {
      opacity: 1;
      font-weight: bold;
      color: #3051e8;
    }
  }
}
</style>
