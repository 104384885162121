<template>
	<footer>
		<ul class="footer-top Gwidth" v-if="active=='/'">
			<li>10年技术沉淀</li>
			<li>50+技术团队</li>
			<li>1000+客户案例</li>
			<li>7*24小时售后</li>
		</ul>
		<div class="footer-center Gwidth">
			<div class="footer-center-left">
				<div class="footer-center-left-item">
					<h5>关于星润</h5>
					<ul>
						<li>走进星润</li>
						<li>荣誉资质</li>
						<li>发展历程</li>
						<li>渠道合作</li>
					</ul>
				</div>
				<div class="footer-center-left-item">
					<h5>公司优势</h5>
					<ul>
						<li>10年+软件开放经验</li>
						<li>完备项目管理体系</li>
						<li>行业资深服务团队</li>
						<li>1000+的客户案例</li> 
					</ul>
				</div>
				<div class="footer-center-left-item">
					<h5>产品服务</h5>
					<ul>
						<li class="al" @click="toPage('/collection')">NFT数藏系统解决方案</li>
						<li class="al" @click="toPage('/shoppingMall')">新零售电商系统解决方案</li>
						<li class="al" @click="toPage('/chatAi')">AI聊天机器人解决方案</li>
						<li class="al" @click="toPage('/digital')">区块链技术开发服务</li>
					</ul>
				</div>
			</div>
			<div class="footer-center-right">
				<div class="footer-center-right-item">
					<h5>关注我们</h5>
					<div class="gzh">
						<div class="img">
							<img src="@/assets/images/gzh.png" alt="" />
						</div>
						<p class="title">公众号</p>
						<!-- <p class="des">
							关注"北京星润科技"<br />
							及时获取最新资讯
						</p> -->
					</div>
				</div>
				<div class="footer-center-right-item">
					<h5></h5>
					<div class="gzh">
						<div class="img">
							<img src="@/assets/images/wx.png" alt="" />
						</div>
						<p class="title">微信客服</p>
						<!-- <p class="des">
							添加企业微信<br />
							了解更多详情
						</p> -->
					</div>
				</div>
				<div class="footer-center-right-item">
					<h5></h5>
					<div class="phone">
						<div class="img">
							<img src="@/assets/ico/icon-phone.png" alt="" />
						</div>
						<p class="title">400-8066-111</p>
						<a href="tel:4008066111">联系我们</a>
					</div>
				</div>
			</div>
		</div>
		<div class="footer-links Gwidth">
			<h5>友情链接：</h5>
			<a href="http://wkz.xingrunkeji.cn/" target="_blank">微客赚魔盒</a>
			<a href="/">网站服务</a>
			<a href="https://mp.weixin.qq.com/" target="_blank"
				>微信公众号开发</a
			>
			<a href="https://mp.weixin.qq.com/cgi-bin/wx" target="_blank"
				>微信小程序开发</a
			>
			<a href="https://cloud.tencent.com/product/sms" target="_blank"
				>短信服务</a
			>
			<a href="https://open.tencent.com/" target="_blank">腾讯开放平台</a>
			<a href="https://cloud.tencent.com/developer/column" target="_blank"
				>腾讯云台</a
			>
			<a href="https://www.vcg.com/" target="_blank">视觉中国</a>
		</div>
		<div class="footer-bottom Gwidth">
			<p>
				COPYRIGHT © 北京星润时代网络科技有限公司 2013-2022
				<a href="http://beian.miit.gov.cn"  target="_blank">京ICP备14055839号-4</a>站长工具
				<a class="ahref"  target="_blank" href="https://www.xingrunkeji.cn/index/icp.pdf"
					>京网公安备：11011502005462号
				</a>
			</p>
			<div class="footer-bottom-ico">
				<img src="@/assets/images/bot1.png" alt="" />
				<img src="@/assets/images/bot2.png" alt="" />
			</div>
		</div>
		<backtop></backtop>
	</footer>
</template>
<script>
	import backtop from "./backtop.vue";
	export default {
		components: {
			backtop,
		},
		data() {
			return {
				active:''
			};
		},
		created() {
    		this.active = this.$route.path;
  		},
		methods:{
			toPage(e) {
     		 this.$router.replace({ path: e });
			}
		}
	};
</script>
<style lang="scss" scoped>
	@media screen and (min-width: 0px) {
		footer {
			border-top: 1px solid #dcdee0;
			padding: 30px 0 40px 0;
			.footer-top {
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex-wrap: wrap;
				border-bottom: 1px solid #ebedf0;
				li {
					padding-left: 38px;
					font-size: 32px;
					line-height: 32px;
					font-weight: 600;
					color: #1d2129;
					width: 50%;
					box-sizing: border-box;
					margin-bottom: 20px;
					&:nth-child(1) {
						background: url(~@/assets/ximg/icon-7@2x.png) left
							center no-repeat;
						background-size: 29px 29px;
					}
					&:nth-child(2) {
						background: url(~@/assets/ximg/icon-8.png) left
							center no-repeat;
						background-size: 29px 29px;
					}
					&:nth-child(3) {
						background: url(~@/assets/ximg/icon-9.png) left
							center no-repeat;
						background-size: 29px 29px;
					}
					&:nth-child(4) {
						background: url(~@/assets/ximg/icon-10.png) left
							center no-repeat;
						background-size: 29px 29px;
					}
				}
			}
			.footer-center {
				padding: 30px 0;
				&-left {
					display: flex;
					align-items: flex-start;
					justify-content: space-between;
					border-bottom: 1px solid #ebedf0;
					padding-bottom: 30px;
					&-item {
						h5 {
							font-size: 28px;
							font-weight: 500;
							color: #1d2129;
							line-height: 28px;
						}
						ul {
							margin-top: 10px;
							li {
								font-size: 26px;
								font-weight: 400;
								color: #646566;
								line-height: 38px;
							}
						}
					}
				}
				&-right {
					padding-top: 30px;
					display: flex;
					align-items: flex-end;
					justify-content: space-between;
					&-item {
						width: 33%;
						text-align: center;
						&:nth-child(1) {
							width: 34%;
						}
						h5 {
							font-size: 28px;
							font-weight: 500;
							color: #1d2129;
							text-align: left;
							margin-bottom: 22px;
						}
						.gzh {
							margin-top: 10px;
							img {
								width: 160px;
								height: 160px;
							}
							.title {
								font-size: 26px;
								font-weight: 500;
								color: #646566;
							}
							.des {
								font-size: 24px;
								font-weight: 400;
								color: #646566;
							}
						}
						.phone {
							.img {
								width: 68px;
								height: 68px;
								display: flex;
								margin: 10px auto;
								img{
									width: 100%;
									height: 100%;								
								}
							}
							.title {
								font-size: 32px;
								font-weight: 500;
								color: #1d2129;
							}
							a {
								display: block;
								width: 100%;
								max-width: 192px;								
								padding: 12px 0;
								background: #2e51fb;
								margin: 0 auto;
								margin-top: 24px;
								font-size: 28px;
								font-weight: 400;
								color: #ffffff;
							}
							margin-bottom: 10px;
						}
					}
				}
			}
			.footer-links {
				border-top: 1px solid #ebedf0;
				padding: 16px 0;
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				h5 {
					font-size: 28px;
					font-weight: 500;
					color: #1d2129;
					margin-bottom: 10px;
				}
				a {
					font-size: 28px;
					font-weight: 400;
					color: #646566;
					margin-right: 24px;
					margin-bottom: 10px;
				}
			}
			.footer-bottom {
				border-top: 1px solid #ebedf0;
				padding-top: 16px;
				font-size: 24px;
				font-weight: 400;
				color: #646566;
				a {
					color: #646566;
				}
				&-ico {
					margin-top: 16px;
					img {
						width: 83px;
						height: 31px;
						margin-right: 10px;
						&:nth-child(2) {
							width: 98px;
							height: 31px;
						}
					}
				}
			}
		}
	}
	@media screen and (min-width: 760px) {
		footer {
			.footer-top {
				li {
					width: auto;
					margin-bottom: 20px;
					font-size: 16px;
					line-height: 30px;
				}
			}
			.footer-center {
				&-left {
					&-item {
						h5 {
							font-size: 14px;
							line-height: 20px;
						}
						ul {
							li {
								font-size: 14px;
								line-height: 24px;
							}
						}
					}
				}
				&-right {
					padding-top: 30px;
					display: flex;
					align-items: flex-end;
					justify-content: space-between;
					flex-direction: row;
					&-item {
						h5 {
							font-size: 14px;
						}
						.gzh {
							img {
								width: 64px;
								height: 64px;
							}
							.title {
								font-size: 12px;
							}
							.des {
								font-size: 14px;
							}
						}
						.phone {
							.img {
								width: 28px;
								height: 28px;
							}
							.title {
								font-size: 16px;
							}
							a {
								font-size: 14px;
								line-height: 36px;
								height: 36px;
								padding: 0;
							}
						}
					}
				}
			}
			.footer-links {
				h5 {
					font-size: 14px;
				}
				a {
					font-size: 14px;
				}
			}
			.footer-bottom {
				font-size: 14px;
			}
		}
	}
	@media screen and (min-width: 820px) {
		footer {
			.footer-center {
				display: flex;
				justify-content: space-between;
				&-left {
					width: calc(50% - 20px);
					border-bottom: 0;
					padding-bottom: 0px;
					position: relative;
					&::after {
						content: "";
						width: 1px;
						height: 100%;
						background-color: #dcdee0;
						position: absolute;
						right: -20px;
						top: 50%;
						transform: translateY(-50%);
					}
				}
				&-right {
					width: calc(50% - 20px);
					padding-top: 0px;
				}
			}
		}
	}
	@media screen and (min-width: 1100px) {
	}
	@media screen and (min-width: 1300px) {
		footer {
			.footer-center {
				&-left {
					width: calc(50% - 70px);
					&::after {
						right: -70px;
					}
				}
				&-right {
					width: calc(50% - 70px);
				}
			}
		}
	}
	.al{
		cursor: pointer;
	}
</style>
