<template>
	<div class="main">
		<ul class="sidebar">
			<li>
				<img src="../assets/ximg/phones.png" alt="" />
				<a class="phone" href="tel:4008066111">400-8066-111</a>
			</li>
			<li>
				<img src="../assets/image/ewm-ico.png" alt="" />
				<div>扫码咨询</div>
				<div class="ewm">
					<img src="../assets/image/ewm.png" alt="" />
				</div>
			</li>
			<li @click="topage('/customizing')">
				<img src="../assets/image/bj-ico.png" alt="" />
				<div>获取报价</div>
			</li>
		</ul>
		<el-backtop :right="40" :bottom="40"></el-backtop>
	</div>
</template>
<script>
	export default {
		methods: {
			topage(e) {
				this.active = e;
				this.$router.replace({ path: e });
				const { aplus_queue } = window;
				aplus_queue.push({
					action: "aplus.sendPV",
					arguments: [
						{
							is_auto: false,
						},
						{
							param1: "111",
							param2: "222",
						},
					],
				});
			},
		},
	};
</script>
<style lang="scss" scoped>
	.sidebar {
		position: fixed;
		right: 0;
		top: 50%;
		z-index: 999;
		li {
			// min-width: 72px;
			// min-height: 72px;
			padding: 15px 15px;
			background: #2b6bff;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			margin-bottom: 30px;
			box-shadow: 0 0 5px rgba($color: #000000, $alpha: 0.4);
			&:hover {
				box-shadow: none;
				.ewm {
					display: block;
				}
			}
			img {
				width: 30px;
				height: 30px;
				margin-bottom: 5px;
			}
			font-size: 16px;
			font-weight: 400;
			color: #ffffff;
			position: relative;
			.phone{				
				cursor: pointer;
				color: #ffffff;
			}
			.ewm {
				position: absolute;
				right: calc(100% + 30px);
				top: 50%;
				transform: translateY(-50%);
				width: 160px;
				height: 160px;
				padding: 20px;
				background-color: #ffffff;
				display: none;
				box-shadow: 0 0 5px rgba($color: #000000, $alpha: 0.4);
				&::after {
					content: "";
					width: 0;
					height: 0;
					border: 24px solid transparent;
					border-left-color: #ffffff;
					position: absolute;
					right: -48px;
					top: 50%;
					transform: translateY(-50%);
				}
				img {
					width: 100%;
					height: 100%;
				}
			}
		}
	}
</style>
